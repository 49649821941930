<template>
    <v-btn :loading="loading" :disabled="loading" :color="color" class="white--text" fab x-small elevation="0"
         @click="store">
        <v-icon dark>
            {{icon}}
        </v-icon>
        <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light>{{icon}}</v-icon>
        </span>
      </template>
    </v-btn>
</template>
<script>
  export default {
       data () {
      return {
        loader: null,
        loading: false,
      }
    },
    methods:{
        store(){
            this.$emit('store');
        },
        load(active){
            this.loading = active
        }
    },
    props: ['color','icon', 'label'],
  }
</script>
<style scoped>
 .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>